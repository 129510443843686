.modal,
.modal-box {
  z-index: 900;
}

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .7);
  overflow: auto;
  animation:opac 0.3s;
  &-close{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    float: right;
    cursor: pointer;
    &Pic{
      font-family: Verdana,sans-serif;
      color:#ddd;
      text-shadow: none;
      opacity: 1;
      background:rgba(0, 0, 0, .9);
      transition: 0.3s;
      width:44px;
      height:44px;
      font-size:24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top:0;
      right:0;
      cursor: pointer;
      z-index: 901;
      @media #{$r414}{
        background:rgba(0, 0, 0, .5);
        width:35px;
        height:35px;
        font-size:18px;
      }
      &:hover{
        color:#fff;
      }
    }
  }
  &-bg {
    content:'';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition:0.6s;
    // background: transparent;    
  }
  &-box {
    position: relative;
    width: 50%;
    max-width: 560px;
    margin: 100px auto;
    

    @media #{$r414} {
      margin: 20px auto 0;
      width: 90%;
    }
    &-img{
      max-width:90%;
      @media #{$r414}{
        max-width: 100%;
        width:100%;
        margin:0;
      }
    }
    &-live{
      max-width:90%;
      width:267px;
      @media #{$r414}{
        margin: 50px auto 0;
      }
    }
  }

  &-header {
    padding: 10px 20px;
    color: #333;
    background: #fff;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e9ecef;
    @media #{$r414} {
      padding: 10px 20px;
    }
  }
  &-title{
    font-size:20px;
  }

  &-body {
    background: #fff;
    padding: 15px 20px;
    line-height: 26px;
    border-radius: 0 0 4px 4px;
  }
  &-btn{
    img{
      opacity:1;
      &:hover{
        opacity: 0.8;
        transition: opacity 0.15s linear;
      }
    }
  }
  
}


