@-webkit-keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }
  
    25%,
    75% {
      opacity: 0;
    }
  }
  
  @keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }
  
    25%,
    75% {
      opacity: 0;
    }
  }
  
.animate-flash {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name:flash;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.animate-fading {
    animation: fading 10s infinite
}

@keyframes fading {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.animate-opacity {
    animation: opac 0.8s
}

@keyframes opac {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.animate-top {
    position: relative;
    animation: animatetop 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.animate-left {
    position: relative;
    animation: animateleft 0.4s
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

.animate-right {
    position: relative;
    animation: animateright 0.4s
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

.animate-bottom {
    position: relative;
    animation: animatebottom 0.4s
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.animate-zoom {
    animation: animatezoom 0.6s
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.animate-input {
    transition: width 0.4s ease-in-out
}

.animate-input:focus {
    width: 100% !important
}

.animate-pop {
    animation-name: modalbox;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0, 0, .3, 1.6);
}



@-webkit-keyframes modalbox {
    0% {
        top: -250px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes modalbox {
    0% {
        top: -250px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}